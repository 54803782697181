@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100;
}

.outline {
  @apply outline-none;
  @apply transition;
  @apply duration-300;
}

.outline:focus {
  @apply ring-1;
  @apply ring-gray-300;
  @apply ring-opacity-90;
}

.fc-daygrid-event,
.fc-timegrid-event {
  border: none !important;
  background-color: transparent !important;
  white-space: normal !important;
}

.cb__menu {
  z-index: 1000 !important;
}

.fc-scrollgrid {
  @apply bg-white;
  @apply rounded-md;
  @apply overflow-hidden;
}

/* TODO: stile per firefox */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
  @apply rounded-full;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
  @apply rounded-full;
}

/* Stile per datepicker */
.datepicker {
  @apply inline-block;
  @apply relative;
  @apply w-7;
  @apply h-8;
}
.datepicker > svg {
  @apply absolute;
  @apply inset-0;
  @apply text-gray-500;
}

.datepicker > input {
  @apply absolute;
  @apply inset-0;
  @apply opacity-0;
  @apply w-full;
  @apply h-full;
}

.datepicker > input::-webkit-calendar-picker-indicator {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

/* This is needed in place of space-x-5 because the latter doesn't work with flex-wrap (i.e. it applies a ml-5 and so the rows are being shifted to the right). */
/* This is still not an ideal solution as it doesn't work for reversed wrap or end aligned items. But it works for the use case of this application. */
/* gap-x-5 could be a solution? */
.space-children > *:not(:last-child) {
  @apply mr-5;
}

.ql-container {
  @apply flex-grow;
  @apply rounded-b-md;
  @apply shadow-sm;
}

.ql-editor {
  height: 100%;
}

.ql-toolbar {
  @apply rounded-t-md;
  @apply shadow-sm;
}
